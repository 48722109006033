export const AddRoleFields = [{
    // Default component is Material UI's TextField
    attribute: "roleid",
    label: "New Role",
    col: {
      // Here you can specify how many Grid columns the field should take for the corresponding breakpoints
      sm: 12,
    },
    component: 'select',
    validationType: "string",
    validations: {
      required: true,
    },
    optionDef: {
      type: "server-object",
      name: "Roles",
      idcolumn: "roleid",
      namecolumn: "rolename"
    },
    optionConfig: {
      key: "id",
      value: "id",
      label: "name",
    },
    options: [
      {
        "id": 0,
        "name": "Administrators"
    },
    {
        "id": 1,
        "name": "Registered Users"
    },
    {
        "id": 2,
        "name": "Subscribers"
    },
    {
        "id": 5,
        "name": "Event Organiser"
    },
    {
        "id": 6,
        "name": "Event Scorer"
    },
    {
        "id": 7,
        "name": "International Approvals"
    },
    {
        "id": 9,
        "name": "ERQI Upload"
    },
    {
        "id": 10,
        "name": "Live Show Jumping Scorer"
    },
    {
        "id": 11,
        "name": "Live Dressage Scorer"
    },
    {
        "id": 12,
        "name": "Live Cross Country"
    },
    {
        "id": 13,
        "name": "Live Scorer (All Disciplines)"
    }
    ] 
  },
  {
    attribute: "effectivedate",
    label: "Effective From",
    component: "date-picker"
  },
  {
    attribute: "expirydate",
    label: "Expires On",
    component: "date-picker"    
  }
];