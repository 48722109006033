import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name, eventClassName, theme) {
//     return {
//       fontWeight:
//         eventClassName.indexOf(name) === -1
//           ? theme.typography.fontWeightRegular
//           : theme.typography.fontWeightMedium,
//     };
//   }
    
export default function EventclassMultieselect( {names, onChange }) {
    const theme = useTheme();
    const [eventClassName, setEventClassName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setEventClassName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
        // console.log(value);
        onChange(value);
    };

    return (
        <div>
        <FormControl sx={{ my:1, minWidth:250 }}>
            <InputLabel id="multiple-chip-label">EventClass</InputLabel>
            <Select
                labelId="multiple-chip-label"
                id="multiple-chip"
                multiple
                value={eventClassName}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" label="Class" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip key={value.id} label={value.name} />
                    ))}
                    </Box>
                )}
                MenuProps={MenuProps}
            >
            {names.map((value) => (
                <MenuItem
                key={value.id}
                value={value}
                // style={getStyles(name, eventClassName, theme)}
                >
                {value.name}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </div>
    );
}